import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  selectMainContent,
  selectIsLoading,
} from "redux-toolkit/main/selectors";
import {
  getMainContentAsync,
  updateMainContentAsync,
} from "redux-toolkit/main/actions";
import { toast } from "react-toastify";

const useMainContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const content = useSelector(selectMainContent, shallowEqual);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (!content || content.length === 0) {
      dispatch(getMainContentAsync());
    }
  }, [dispatch, content]);

  const updateContent = useCallback(
    (id, data) => {
      toast.dismiss();
      toast.loading(t("main.publishing"));
      dispatch(updateMainContentAsync({ id, data: { content: data } })).then(
        (res) => {
          toast.dismiss();
          toast.success(t("main.published"));
          dispatch(getMainContentAsync());
        },
      );
    },
    [dispatch, t],
  );

  return {
    content,
    updateContent,
    isLoading,
  };
};

export default useMainContent;
