import { useFormik } from "formik";
import { articleFormSchema } from "libs/validation/schemas";
import mediaUploader from "api/mediaUploader.api";
import useAuth from "hooks/useAuth";
import { CACHE_SIZE_UNLIMITED } from "firebase/firestore";
import { runFireworks } from "components/confetti";

const FIELDS = {
  TITLE: "title",
  DESCRIPTION: "description",
  POSTER: "poster",
  CONTENT: "content",
  CACHE_SIZE_UNLIMITED,
  CATEGORIES: "categories",
  VIDEO: "videoUrl",
  PUBLISHED: "published",
  PLAN: "plan",
};

const useProgramForm = (props) => {
  const { program, create, update, id } = props;
  const { currentUser } = useAuth();
  const programForm = useFormik({
    initialValues: {
      [FIELDS.TITLE]: program.title || "",
      [FIELDS.POSTER]: program.poster || "",
      [FIELDS.DESCRIPTION]: program.description || "",
      [FIELDS.CONTENT]: program.content || "",
      [FIELDS.CATEGORIES]: program.categories || [],
      [FIELDS.VIDEO]: program.videoUrl || "",
      [FIELDS.PUBLISHED]: program.published || false,
      [FIELDS.PLAN]: program.plan || "",
    },
    enableReinitialize: true,
    validationSchema: articleFormSchema,
    onSubmit: async (values) => {
      let posterUrl = "";
      let contentUrl = "";
      let planUrl = "";
      if (typeof values[FIELDS.POSTER] !== "string") {
        posterUrl = await mediaUploader(
          values[FIELDS.POSTER],
          `programs/${id}/poster`,
        );
      } else {
        posterUrl = values[FIELDS.POSTER];
      }

      if (values[FIELDS.CONTENT]) {
        const blob = new Blob([values[FIELDS.CONTENT]], { type: "text/plain" });
        const file = new File([blob], "content.txt", { type: "text/plain" });
        contentUrl = await mediaUploader(file, `programs/${id}/content`);
      }

      if (typeof values[FIELDS.PLAN] !== "string") {
        const blob = new Blob([values[FIELDS.PLAN]], {
          type: "application/pdf",
        });
        const file = new File([blob], `${id}.pdf`, {
          type: "application/pdf",
        });
        planUrl = await mediaUploader(file, `programs/plans/${id}`);
      }

      create({
        ...values,
        poster: posterUrl,
        createdAt: Date.now(),
        content: contentUrl,
        author: { id: currentUser?.id, displayName: currentUser.displayName },
        plan: planUrl,
      });
      update(id, {
        ...values,
        poster: posterUrl,
        content: contentUrl,
        updatedAt: Date.now(),
        plan: planUrl || program.plan || "",
      });
      runFireworks();
    },
  });

  const { values, setFieldValue, handleSubmit, resetForm } = programForm;

  return {
    programForm,
    FIELDS,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    errors: programForm.errors,
  };
};

export default useProgramForm;
