import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  TextareaAutosize as BaseTextareaAutosize,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import ImageAttachment from "components/image-cropper/ImageAttachment";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const DEFAULT_IMAGE_URL =
  "https://assets.promptbase.com/DALLE_IMAGES%2F0wbTCfH5rym9qX6gukTX%2Fresized%2F1690684977463_800x800.webp?alt=media&token=c39b1396-3bb9-493f-8829-e963804d6b52";

const ArticleOverview = ({
  overview,
  errors,
  setFieldValue,
  deleteArticle,
  id,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-8 items-center w-full px-20">
      <div className="w-3/4 flex flex-col gap-2">
        <div>
          <Checkbox
            checked={overview?.published}
            color="primary"
            onChange={(e) => {
              setFieldValue("published", !overview?.published);
            }}
            value="Հրապարակել"
          />
          <span>Հրապարակված է</span>
        </div>
        <TextField
          multiline
          minRows={1}
          maxRows={3}
          size="small"
          value={overview?.title || ""}
          onChange={(e) => setFieldValue("title", e.target.value)}
          fullWidth
          label={t("articles.title")}
          error={Boolean(errors?.title)}
          helperText={t(errors?.title)}
        />
        <Textarea
          multiline
          minRows={2}
          maxRows={8}
          size="small"
          value={overview?.description || ""}
          onChange={(e) => setFieldValue("description", e.target.value)}
          fullWidth
          label={t("articles.desc")}
          error={Boolean(errors?.description)}
          helperText={t(errors?.description)}
        />
        <div className="flex flex-row">
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("analysis")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value,
                        )
                      : [...(overview?.categories || []), e.target.value],
                  )
                }
                value="analysis"
              />
            }
            label={t("articles.categories.analysis")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("event")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value,
                        )
                      : [...(overview?.categories || []), e.target.value],
                  )
                }
                value="event"
              />
            }
            label={t("articles.categories.event")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("assembly")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value,
                        )
                      : [...(overview?.categories || []), e.target.value],
                  )
                }
                value="assembly"
              />
            }
            label={t("articles.categories.assembly")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("special")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value,
                        )
                      : [...(overview?.categories || []), e.target.value],
                  )
                }
                value="special"
              />
            }
            label={t("articles.categories.special")}
          />
        </div>
      </div>
      <div className="flex v1/2 gap-10">
        <ImageAttachment
          crop
          aspect={1.5}
          containerClassName="relative w-full"
          onSave={(file, url) => setFieldValue("poster", file)}
          imageClassName="object-cover h-[225px] w-full"
          image={overview?.poster}
          removeImage={() => setFieldValue("poster", "")}
          defaultUrl={DEFAULT_IMAGE_URL}
        />
      </div>
      {id && (
        <Button
          color="error"
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
        >
          Ջնջել Հոդվածը
        </Button>
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle> Հաստատում </DialogTitle>
        <DialogContent dividers>
          Դուք համոզված ե՞ք, որ հոդվածը պետք է ջնջվի։
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Չեղարկել
          </Button>
          <Button
            onClick={() => {
              deleteArticle();
              setOpen(true);
              navigate("/articles");
            }}
          >
            Ջնջել
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ArticleOverview;
