import Dashboard from "../../pages/dashboard/Dashboard";
import Login from "../../pages/login/LoginPage";
import Register from "components/auth/RegisterPage";
import NotFound from "../main/NotFound";
import Articles from "pages/articles/Articles";
import ManageArticle from "pages/articles/manage/ManageArticle";
import Article from "pages/articles/Article";
import Programs from "pages/programs/Programs";
import ManageProgram from "pages/programs/manage/ManageProgram";
import Program from "pages/programs/Program";
import Profile from "pages/profile/Profile";
import Main from "pages/main/Main";

export const protectedRoutes = [
  { path: "/", element: <Dashboard /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/profile", element: <Profile /> },
  { path: "/profile/:id", element: <Profile /> },
  { path: "/articles", element: <Articles /> },
  { path: "/articles/:id", element: <Article isEditable={false} /> },
  { path: "/articles/manage", element: <ManageArticle isEditable={true} /> },
  {
    path: "/articles/manage/:id",
    element: <ManageArticle isEditable={true} />,
  },
  { path: "/programs", element: <Programs /> },
  { path: "/programs/:id", element: <Program isEditable={false} /> },
  { path: "/programs/manage", element: <ManageProgram isEditable={true} /> },
  {
    path: "/programs/manage/:id",
    element: <ManageProgram isEditable={true} />,
  },
  { path: "/main", element: <Main /> },
  { path: "*", element: <NotFound /> },
];

export const routes = [
  { path: "/login", element: <Login /> },
  { path: "/register", element: <Register /> },
  { path: "*", element: <NotFound /> },
];
