import React, { useRef, useMemo } from "react";
import JoditEditor from "jodit-react";

const TextEditor = ({ value, setValue, placeholder }) => {
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: placeholder || "Խմբագրել...",

      toolbarAdaptive: false, // Disable automatic hiding of buttons
      buttons:
        "undo,redo,|,fontsize,brush,bold,italic,underline,strikethrough,|,align,ul,ol,|,table,link,unlink,image,video,|,source,preview",
      buttonsXS: "bold,italic,|,ul,ol", // Toolbar buttons for small screens
    }),
    [placeholder],
  );

  return (
    <JoditEditor
      ref={editor}
      value={value}
      config={config}
      tabIndex={1} // tabIndex of textarea
      onBlur={(newContent) => setValue(newContent)} // preferred to use only this option to update the content for performance reasons
      onChange={(newContent) => {}}
    />
  );
};

export default TextEditor;
