import React, { useRef, useState } from "react";
import { PiFilePdfFill } from "react-icons/pi";

const UploadPDF = ({ src, setFile }) => {
  const fileBrowser = useRef(null);
  const [url, setUrl] = useState("");
  return (
    <>
      <div
        onClick={() => fileBrowser.current.click()}
        className="flex justify-center gap-2 w-full items-center h-full opacity-100 mb-4"
      >
        <PiFilePdfFill size={20} color="red" />
        <span className="text-black cursor-pointer text-sm">Կցել PDF ֆայլ</span>

        <input
          onChange={(e) => {
            const file = e.target.files[0];

            if (file) {
              setUrl(URL.createObjectURL(file));
              setFile(file);
            }
          }}
          accept="application/pdf"
          type="file"
          style={{ display: "none" }}
          ref={fileBrowser}
        />
      </div>

      {(url || src) && (
        <iframe
          width="100%"
          height="660px"
          src={url || src}
          title="PDF Plan"
          allowFullScreen
        />
      )}
    </>
  );
};

export default UploadPDF;
