import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Animate } from "react-animate-mount";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { TextField } from "@mui/material";
import Stepper from "components/stepper/Stepper";
import ArticleOverview from "components/articles/ArticleOverview";
import TextEditor from "components/textEditor/TextEditor";
import useArticle from "hooks/articles/useArticle";
import useArticleForm from "hooks/articles/useArticleForm";
import ArticleView from "components/articles/ArticleView";
import UploadPDF from "components/pdf/UploadPDF";

const ManageArticle = ({ isEditable }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const { current, updateArticle, createArticle, deleteArticle } = useArticle({
    id,
  });
  const { errors, FIELDS, values, setFieldValue, handleSubmit } =
    useArticleForm({
      id,
      article: current || {},
      create: !id ? createArticle : () => {},
      update: id ? updateArticle : () => {},
    });
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const steps = [
    {
      id: 1,
      title: "Step 1",
      desc: t("articles.general"),
      content: (
        <ArticleOverview
          errors={{
            title: errors[FIELDS.TITLE],
            description: errors[FIELDS.DESCRIPTION],
            poster: errors[FIELDS.POSTER],
          }}
          overview={{
            title: values[FIELDS.TITLE],
            description: values[FIELDS.DESCRIPTION],
            poster: values[FIELDS.POSTER],
            categories: values[FIELDS.CATEGORIES],
            published: values[FIELDS.PUBLISHED],
          }}
          setFieldValue={setFieldValue}
          deleteArticle={deleteArticle}
          id={id}
        />
      ),
    },
    {
      id: 3,
      title: "Step 3",
      desc: t("articles.content"),
      content: (
        <div className="flex flex-col gap-4 pb-20">
          <UploadPDF
            src={values[FIELDS.PLAN]}
            setFile={(file) => setFieldValue(FIELDS.PLAN, file)}
          />
          <TextField
            onChange={(e) => {
              setFieldValue(FIELDS.VIDEO, e.target.value);
            }}
            value={values[FIELDS.VIDEO]}
            height="100%"
            width="100%"
            label="Ավելացնել տեսանյութի հղում"
            fullWidth
            className="mb-10"
          />
          <TextEditor
            isEditable={isEditable}
            setValue={(value) => {
              setFieldValue(FIELDS.CONTENT, value);
            }}
            value={values[FIELDS.CONTENT]}
            height="100%"
            width="100%"
          />
        </div>
      ),
    },
    {
      id: 4,
      title: "Step 3",
      desc: t("articles.review"),
      content: <ArticleView isEditable={isEditable} article={values} />,
    },
    {
      id: 5,
      title: "Step 3",
      desc: t("articles.publish"),
      content: (
        <div className="flex items-center justify-center text-green-600 text-[32px]">
          Հրապարակված է
        </div>
      ),
    },
  ];

  return (
    <div className="mt-10 flex flex-col justify-center">
      <Animate show={isMounted}>
        <Stepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isWithErrors={Object.keys(errors).length}
        />
      </Animate>
      <div className="flex w-full justify-between mt-10">
        {activeStep < steps.length - 1 && (
          <div
            onClick={() => activeStep > 0 && setActiveStep(activeStep - 1)}
            className={`p-2 bg-opacity-50 z-50 flex ${
              activeStep > 0 ? "opacity-100 cursor-pointer" : "opacity-0"
            }`}
          >
            <FaChevronLeft size={32} color="gray" />
          </div>
        )}
        <div className="w-full">
          {steps.map((step, index) => {
            return (
              <Animate show={index === activeStep} key={index}>
                {step.content}
              </Animate>
            );
          })}
        </div>
        {activeStep <= 3 &&
          !Object.keys(errors).length &&
          values[FIELDS.TITLE] && (
            <div
              onClick={() => {
                if (activeStep === 2) {
                  setActiveStep(activeStep + 1);
                  handleSubmit();
                } else {
                  setActiveStep(activeStep + 1);
                }
              }}
              className={`p-2 bg-opacity-50 cursor-pointer flex justify-end ${
                steps.length !== activeStep + 1 ? "opacity-100" : "opacity-0"
              }`}
            >
              <FaChevronRight size={32} color="gray" />
            </div>
          )}
      </div>
    </div>
  );
};

export default ManageArticle;
