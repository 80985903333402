import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  limit,
  addDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  startAfter,
  getCountFromServer,
  where,
} from "firebase/firestore";
import { db } from "config/firebase";

const getDocument = (id) => {
  return doc(db, "articles", id);
};

export const getData = async (reference) => {
  if (reference) {
    const docSnap = await getDoc(reference);
    return docSnap.exists ? { id: docSnap?.id, ...docSnap.data() } : null;
  }
  return null;
};

export const getArticles = async (last, category) => {
  try {
    // Query to get the articles
    let q = category
      ? query(
          collection(db, "articles"),
          where("categories", "array-contains", category),
          orderBy("createdAt", "desc"),
          ...(last ? [startAfter(last)] : []),
          limit(10),
        )
      : query(
          collection(db, "articles"),
          orderBy("createdAt", "desc"),
          ...(last ? [startAfter(last)] : []),
          limit(10),
        );

    const documentSnapshots = await getDocs(q);

    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];

    const articles = documentSnapshots.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });

    // Get the total count of articles in the collection
    let countQuery = category
      ? query(
          collection(db, "articles"),
          where("categories", "array-contains", category),
          orderBy("createdAt", "desc"),
        )
      : query(collection(db, "articles"), orderBy("createdAt", "desc"));
    const countSnapshot = await getCountFromServer(countQuery);
    const totalArticlesCount = countSnapshot.data().count;

    // Return articles, last document, and total count of articles
    return { articles, last: lastVisible, totalArticlesCount };
  } catch (error) {
    console.error(`Error while getting articles: ${error}`);
    return error;
  }
};

export const getArticlesLength = async () => {
  const collectionRef = collection(db, "articles");
  const snapshot = await getDocs(collectionRef);
  return snapshot.size;
};

export const getArticleById = async (id) => {
  const docRef = getDocument(id);
  const docSnap = await getDoc(docRef);
  const lesson = docSnap.data();
  return docSnap.exists ? { id: docSnap.id, ...lesson, reviewers: [] } : null;
};

export const createArticle = async (article) => {
  try {
    const newArticle = await addDoc(collection(db, "articles"), article);
    return newArticle;
  } catch (e) {
    console.error(`Error while creating article ${e}`);
    return false;
  }
};

export const updateArticles = async (id, data) => {
  try {
    await updateDoc(getDocument(id), data);
  } catch (e) {
    console.error(`Error while updating article ${e}`);
    return false;
  }
};

export const deleteArticle = async (id) => {
  try {
    await deleteDoc(getDocument(id));
  } catch (e) {
    console.error(`Error while deleting article ${e}`);
    return false;
  }
};
